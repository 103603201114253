<template>
  <div class="grid place-items-center h-screen vld-parent gap-2 align-top">
    <transition name="fade">
      <loading
        v-if="isSubmittedOrLoading || message !== ''"
        :active="true"
        :can-cancel="false"
        :is-full-page="true"
        :opacity="0.75"
      >
        <template #before>
          <div>
            <h2
              id="visualFormPageTitle"
              class="font-medium leading-tight text-4xl text-blue-600"
            >
              {{ getPageTitle }}
            </h2>
          </div>
        </template>

        <template v-if="hideLoadingSpinner" #default>
          <!-- get rid of the loading spinner on submission -->
          <div class="flex justify-center mt-14"></div>
        </template>

        <template v-else-if="isLoading" #default>
          <div class="flex justify-center">
            <component
              :is="loadingIcon"
              color="#000"
              :width="width"
              :height="height"
            ></component>
          </div>
        </template>
      </loading>
    </transition>
    <!-- Render Images, attachments in v-for phase 2  -->
    <div>
      <div
        style="max-width: 500px;"
        class="mb-6 h-full"
        v-for="(attachment, index) in attachments"
        :key="`attachment_${index}`"
      >
        <template v-if="isAttachmentTypeImage(attachment.attachment_type)">
          <ImageAttachment
            :id="`visualFormImage_${attachment.attachment_id}`"
            :src="attachment.file_url"
            :key="attachment.file_url"
            class="w-full"
          />
        </template>
        <template v-else-if="isAttachmentTypeAudio(attachment.attachment_type)">
          <div class="w-full bg-white shadow-md rounded p-4 mb-4">
            <audio
              controls
              style="width: 100%;"
              :src="attachment.file_url"
            ></audio>
          </div>
        </template>
        <template v-else>
          <iframe
            @load="
              adjustIFrame(`visualFormDocument_${attachment.attachment_id}`)
            "
            style="border: 0;"
            class="bg-white shadow-md rounded"
            :id="`visualFormDocument_${attachment.attachment_id}`"
            :src="attachment.file_url"
          ></iframe>
        </template>
      </div>

      <form
        v-if="hasFormParams"
        style="height: 100%"
        class="w-full bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4"
      >
        <div
          v-for="(form_param, index) in form_params"
          :key="`form_params_${index}`"
          class="mb-6"
        >
          <label
            class="block text-blue-600 text-sm font-bold mb-2"
            :for="`form_params_${form_param.field_id}`"
            :id="`form_params_label_${form_param.field_id}`"
          >
            {{ form_param.field_title }}
          </label>

          <!-- File upload input field -->
          <file-upload
            v-if="shouldDisplayUploadField(form_param.field_type)"
            v-model="form_params[index]"
            :accept="getFileTypeUploadWhitelist(form_param.field_type)"
            uri-output-key="value"
            mime-output-key="secondary_value"
            :session-id="uuid"
            :name="form_param.field_title"
            @startUpload="fileUploadStarted"
            @finishUpload="fileUploadFinished"
          />

          <!-- Default input field -->
          <input
            v-else
            class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            :id="`form_params_${form_param.field_id}`"
            v-model="form_param.value"
            type="text"
            :placeholder="form_param.field_placeholder"
          />

          <p
            :id="`form_params_description_${form_param.field_id}`"
            class="text-gray-400 text-xs mt-3 break-words"
          >
            {{ form_param.field_description }}
          </p>
        </div>
        <div class="">
          <button
            class="bg-blue-500 hover:bg-blue-700 text-white font-bold mr-2 mb-2 py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            type="button"
            id="form_fields_submit"
            @click.stop.prevent="submitPage(-1)"
            :disabled="hasPendingUpload"
            :class="{
              'opacity-50': hasPendingUpload,
              'cursor-not-allowed': hasPendingUpload
            }"
          >
            {{ pageLabels.defaultSubmitButton }}
          </button>
        </div>
      </form>

      <div
        v-for="(button, index) in buttons"
        :key="`button_${index}`"
        class="mb-6 w-full"
        style="max-width: 500px"
      >
        <button
          class="w-full bg-blue-500 hover:bg-blue-700 text-white font-bold py-4 px-4 rounded text-xl"
          :id="`button_${button.content_id}`"
          @click.stop.prevent="submitPage(button.content_id)"
        >
          {{ button.content_label }}
        </button>
      </div>
    </div>

    <!-- Render Form params in v-for phase 3  -->
  </div>
</template>

<script>
import echo from "@/echo";
import { mapGetters, mapActions, mapState } from "vuex";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import Spinner from "vue-loading-overlay/src/loaders/spinner";
import { ac_headers } from "@/utils/studio7ApiService";
import _ from "lodash";
import ImageAttachment from "./components/ImageAttachment";
import FileUpload from "./components/FileUpload";
import {
  VISUAL_FORM_FIELD_TYPES,
  VISUAL_FORM_FIELD_TYPE_CONST
} from "@/constants/visualFormFieldTypes.js";

export default {
  name: "VisualFormPage",
  props: {
    uuid: {
      required: true,
      type: [String]
    }
  },
  components: {
    Loading,
    Spinner,
    ImageAttachment,
    FileUpload
  },
  data() {
    return {
      channel: false,
      attachments: [],
      buttons: [],
      form_params: [],
      fieldPendingUpload: [],
      loadingIcon: "spinner",
      width: 64,
      height: 64,
      node_id: false,
      pageLabels: {
        submitted: "Submitted",
        submitting: "Submitting",
        loading: "Loading",
        defaultSubmitButton: "Submit"
      }
    };
  },
  computed: {
    ...mapState("app", {
      isLoading: state => state.isLoadingOptions
    }),
    ...mapGetters("visualForm", {
      message: "message",
      submitted: "submitted",
      contentId: "contentId",
      interactionsUrl: "interactionsUrl"
    }),

    /**
     * get page title based on loading state
     */
    getPageTitle() {
      if (this.message !== "") {
        return this.message;
      }
      if (this.isLoading) {
        return this.submitted
          ? this.pageLabels.submitting
          : this.pageLabels.loading;
      } else {
        return this.submitted ? this.pageLabels.submitted : "";
      }
    },

    /**
     * check is submitted or loading
     * @returns {*}
     */
    isSubmittedOrLoading() {
      return this.submitted || this.isLoading;
    },

    /**
     * has form parameters
     * @returns {boolean}
     */
    hasFormParams() {
      return this.form_params.length > 0;
    },

    /**
     * hide the loading spinner
     * @returns {*|boolean}
     */
    hideLoadingSpinner() {
      return (
        (this.submitted && !this.isLoading) ||
        (!this.submitted && !this.isLoading)
      );
    },

    /**
     * Check if we have any pending uploads
     */
    hasPendingUpload() {
      return this.fieldPendingUpload.length > 0;
    }
  },

  /**
   * created actions
   */
  created() {
    window.addEventListener("beforeunload", this.beforeWindowUnload);

    document.addEventListener("visibilitychange", () => {
      if (document.visibilityState === "hidden") {
        this.beforeWindowUnload();
      }
    });
  },

  /**
   * before destroy actions
   */
  beforeDestroy() {
    window.removeEventListener("beforeunload", this.beforeWindowUnload);
  },

  mounted() {
    this.toggleOptionsLoading(true);
    this.loadInteractionsUrl().then(() => {
      if (this.interactionsUrl !== "") {
        this.openVisualForm({
          url: this.interactionsUrl,
          call_uuid: this.uuid
        });
      }
    });

    // wait for websocket response
    this.loadData();
  },

  methods: {
    ...mapActions("app", {
      toggleOptionsLoading: "toggleOptionsLoading"
    }),
    ...mapActions("visualForm", {
      loadInteractionsUrl: "loadInteractionsUrl",
      setInteractionsUrl: "setInteractionsUrl",
      openVisualForm: "openVisualForm",
      closeVisualForm: "closeVisualForm",
      submitVisualForm: "submitVisualForm",
      setSubmitted: "setSubmitted",
      setMessage: "setMessage"
    }),

    /**
     *
     */
    shouldDisplayUploadField(fieldType) {
      return (
        [
          VISUAL_FORM_FIELD_TYPE_CONST.IMAGE_UPLOAD,
          VISUAL_FORM_FIELD_TYPE_CONST.DOCUMENT_UPLOAD,
          VISUAL_FORM_FIELD_TYPE_CONST.AUDIO_UPLOAD
        ].indexOf(fieldType) >= 0
      );
    },

    /**
     * Get the list of whitelisted file types for upload for the given field type
     */
    getFileTypeUploadWhitelist(fieldType) {
      const type = VISUAL_FORM_FIELD_TYPES.filter(
        type => type.value === fieldType
      ).pop();

      return _.get(type, "allowedMime", []);
    },

    /**
     * Check if we should ignore the current message
     * @param {VisualFormPayload} payload The visual form payload to compare the state with
     */
    isSameState(payload) {
      // A form is in the same state if the node ID & the submit state is the same
      return (
        this.node_id === payload.node_id &&
        this.submitted === payload.is_submitted
      );
    },

    isValidUrl(payload) {
      if (!_.has(payload, "callback_url")) {
        return false;
      }

      try {
        new URL(payload.callback_url);
        return true;
      } catch (e) {
        return false;
      }
    },

    /**
     * load the data from the web socket
     */
    loadData() {
      let self = this;

      echo
        .channel("visual_form." + this.uuid)
        .listen("VisualFormOpened", ({ payload }) => {
          // No need to update the form state if the payload state and the current state is the same
          // since this will reset the user input
          if (self.isSameState(payload)) {
            return;
          }

          this.node_id = payload.node_id;

          // if have an interaction url in payload, set it
          if (self.isValidUrl(payload)) {
            self.setInteractionsUrl(payload.callback_url);
          }

          let visualFormData = payload.payload;

          if (visualFormData.attachments !== undefined) {
            self.attachments = visualFormData.attachments;
          }

          if (visualFormData.buttons !== undefined) {
            self.buttons = visualFormData.buttons;
          }

          if (visualFormData.form_params !== undefined) {
            self.form_params = _.map(
              _.cloneDeep(visualFormData.form_params),
              formParams => {
                formParams.value = "";
                formParams.secondary_value = "";
                return formParams;
              }
            );
          }

          // add label retrieval here

          // already submitted stop the loading spinner and display submitted, otherwise
          if (payload.is_submitted !== undefined) {
            if (payload.is_submitted === true) {
              self.toggleOptionsLoading(false);
              self.setSubmitted(true);
            } else {
              setTimeout(() => {
                //clear the previous message and submitted/loading flags
                self.setMessage("");
                self.setSubmitted(false);
                self.toggleOptionsLoading(false);
              }, 500);
            }
          }
        });
    },
    /**
     * submit the page
     * @param contentId
     */
    submitPage(contentId) {
      this.submitVisualForm({
        url: this.interactionsUrl,
        data: {
          call_uuid: this.uuid,
          payload: {
            button: contentId,
            form: this.form_params
          },
          node_id: this.node_id
        },
        ac_headers: ac_headers()
      });
    },

    /**
     * check attachment type image
     * @param attachmentType
     * @returns {boolean}
     */
    isAttachmentTypeImage(attachmentType) {
      return attachmentType === "image";
    },

    /**
     * check attachment type audio
     * @param attachmentType
     * @returns {boolean}
     */
    isAttachmentTypeAudio(attachmentType) {
      return attachmentType === "audio";
    },

    /**
     * adjust the iframe height
     * @param iframeId
     */
    adjustIFrame(iframeId) {
      let iframe = document.getElementById(iframeId);
      let maxW = iframe.scrollWidth;
      let minW = maxW;
      let frameH = 100; //IFrame starting height
      iframe.style.height = frameH + "px";
      let maxResize = 0;

      while (minW === maxW && maxResize < 10) {
        frameH = frameH + 100; //Increment
        iframe.style.height = frameH + "px";
        minW = iframe.scrollWidth;
        maxResize++;
      }
    },

    /**
     * handle navigate away
     * @param e
     */
    beforeWindowUnload() {
      return this.closeVisualForm({
        url: this.interactionsUrl,
        data: { call_uuid: this.uuid, ac_headers: ac_headers() }
      });
    },

    /**
     * Handler for when file upload started
     */
    fileUploadStarted(fieldId) {
      this.fieldPendingUpload.push(fieldId);
    },

    /**
     * Handler for when file upload finished
     */
    fileUploadFinished(fieldId) {
      const index = this.fieldPendingUpload.indexOf(fieldId);
      this.fieldPendingUpload.splice(index, 1);
    }
  }
};
</script>
<style type="text/css">
.loader {
  border-top-color: #3498db;
  -webkit-animation: spinner 1.5s linear infinite;
  animation: spinner 1.5s linear infinite;
}

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

iframe {
  position: relative;
  border: none;
  width: 500px;
  height: 100%;
}

.fade-leave-active,
.fade-enter-active {
  transition: opacity 0.3s ease;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
